import { Skeleton, Spacer } from '@chakra-ui/react';
import useCubeLTG from 'hooks/useCubeLTG';
import { groupBy, max, uniq } from 'lodash';
import { Data, PlotlyDataLayoutConfig } from 'plotly.js';
import { useMemo } from 'react';
import { createLocationPallet } from 'shared/functions/colorPallets';
import {
  PlanktonThreshold,
  planktonThresholdToColor,
  planktonThresholdValue,
  thresholdForSpeciesValue
} from 'shared/plankton';
import GraphError from '../GraphError';
import NoData from '../NoData';
import { ControllerInputs } from '../Plankton/TrendsController';
import Plot, { plotDates } from '../Plot';
import { BaseChartProps, PlanktonTrendSettings } from '../types';

type PlanktonCubeDatum = {
  'TessPlanktonLookup.sublocation'?: string;
  'Site.id'?: string;
  'TessPlankton.measuredAt': string;
  'TessPlankton.measuredAt.minute': string;
  'TessPlanktonLookup.species': string;
  'TessPlanktonLookup.depth': string;
  'TessPlankton.avgCellCount': number;
  'TessPlankton.maxCellCount': number;
};

// Target data structure for plot output
type PlanktonStructure = {
  [sublocation: string]: {
    [depth: number]: {
      [species: string]: {
        [measuredAt: string]: {
          avgCellCount: number;
          maxCellCount: number;
          thresholdVal: number;
          threshold: PlanktonThreshold;
          globalMaxCellCount: number;
        };
      };
    };
  };
};

const hexToRgba = (hexCode: string, alpha: number): string => {
  const rgb = hexCode.substring(1);
  const rgbCode = [];
  for (const i of [0, 2, 4]) {
    // get base 16 integer val
    const group = parseInt(rgb.substring(i, i + 2), 16);
    rgbCode.push(group);
  }
  rgbCode.push(alpha);
  return 'rgba(' + rgbCode.toString() + ')';
};

const Chart = ({
  granularity = 'minute',
  dateRange = 'from 10080 minutes ago to 1 day from now',
  chartRange,
  control,
  skip,
  settings: {
    selectedSublocations = ['All'],
    comparingSublocations,
    selectedSpecies = 'All',
    depthAveraged: depthSumAggregate = true,
    groupSublocations: groupSubplots = true,
    ...settings
  }
}: BaseChartProps<PlanktonTrendSettings, ControllerInputs>) => {
  const locationDimension = settings.site?.smbId ? 'TessPlanktonLookup.sublocation' : 'Site.id';

  const transform = (rawData: PlanktonCubeDatum[]): PlanktonStructure => {
    let filteredDataSpp = rawData;
    const rawGlobalMaxCellCount = max(
      Object.values(rawData).map((d) => d['TessPlankton.avgCellCount'])
    );
    if (selectedSpecies.toString() != 'All') {
      // filter to spp.
      filteredDataSpp = rawData.filter((d) => d['TessPlanktonLookup.species'] == selectedSpecies);
    }
    let filteredDataSublocation = filteredDataSpp;
    const sublocationLookupValues = settings.site?.smbId
      ? selectedSublocations
      : Object.values(selectedSublocations).flatMap((d) => {
          return Object.keys(settings.project.siteNameMappings).find(
            (sublocId) => settings.project.siteNameMappings[sublocId] === d
          );
        });
    const comparingSublocationsLookupValues = settings.site?.smbId
      ? comparingSublocations
      : comparingSublocations
        ? Object.values(comparingSublocations).flatMap((d) => {
            return Object.keys(settings.project.siteNameMappings).find(
              (sublocId) => settings.project.siteNameMappings[sublocId] === d
            );
          })
        : undefined;
    // Filter logic gets a little complicated here with all of the cases.
    // but much of the complexity is to handle "All" when it comes up in one or both Select boxes.
    if (
      (selectedSublocations.length > 0 && comparingSublocations?.length > 0) ||
      (selectedSublocations.length > 0 &&
        selectedSublocations.includes('All') &&
        comparingSublocations?.length > 0)
    ) {
      // Case 1 : Selections in Group A or Group B
      if (groupSubplots) {
        // setup comparison of selected vs. not-selected sublocatitons
        let selected = [];
        if (!selectedSublocations.includes('All')) {
          // Use selected values for Group A
          selected = filteredDataSublocation.filter((d) =>
            sublocationLookupValues.includes(d[locationDimension].toString())
          );
        } else {
          // Use All values for Group A
          selected = filteredDataSublocation;
        }
        // Label data records for Group A
        selected = selected.map((d) => ({
          ...d,
          [locationDimension]: 'sublocation group A'
        }));
        let unselected = [];
        if (comparingSublocations?.length > 0) {
          if (!comparingSublocations?.includes('All')) {
            // Use selected values for Group B
            unselected = filteredDataSublocation.filter((d) =>
              comparingSublocationsLookupValues?.includes(d[locationDimension].toString())
            );
          } else {
            // Use All values for Group B
            unselected = filteredDataSublocation;
          }
          // Label data records for Group B
          unselected = unselected.map((d) => ({
            ...d,
            [locationDimension]: 'sublocation group B'
          }));
        } else {
          // No input for group B, so use all values not in selected
          unselected = filteredDataSublocation
            .filter((d) => !sublocationLookupValues.includes(d[locationDimension].toString()))
            .map((d) => ({
              ...d,
              [locationDimension]: 'unselected sublocations'
            }));
        }
        filteredDataSublocation = [...selected, ...unselected];
      } else {
        // Not grouping
        if (!selectedSublocations.includes('All')) {
          filteredDataSublocation = filteredDataSublocation.filter((d) =>
            sublocationLookupValues.includes(d[locationDimension].toString())
          );
        }
      }
    } else {
      // No comparison sites.
      // Filter to any Group A selections
      if (selectedSublocations.length > 0) {
        if (!selectedSublocations.includes('All')) {
          filteredDataSublocation = filteredDataSublocation.filter((d) =>
            sublocationLookupValues.includes(d[locationDimension].toString())
          );
        }
      }
      if (groupSubplots) {
        // No sublocations selected for either group
        // Display aggregated data for all sites
        filteredDataSublocation = filteredDataSublocation.map((d) => ({
          ...d,
          [locationDimension]: 'all sublocations'
        }));
      }
    }
    // Add threshold values from settings.project using counts of plankton.
    const processedPlanktonData = filteredDataSublocation.map((d) => ({
      ...d,
      thresholdVal: planktonThresholdValue(
        settings.project,
        d['TessPlanktonLookup.species'],
        d['TessPlankton.maxCellCount']
      ),
      threshold: thresholdForSpeciesValue(
        settings.project,
        d['TessPlanktonLookup.species'],
        d['TessPlankton.maxCellCount']
      ),
      'TessPlanktonLookup.depth': d['TessPlanktonLookup.depth'] || 'depthAvg'
    }));
    const bySublocation = groupBy(processedPlanktonData, locationDimension);
    // Aggregate on sublocation and depth, assigning value for each grouping
    const depthData = Object.entries(bySublocation)
      .sort((one, two) => (one > two ? 1 : -1))
      .reduce((sublocAcc, [sublocation, dataAtSublocation]) => {
        // Aggregate on species
        const byDepth = Object.entries(groupBy(dataAtSublocation, 'TessPlanktonLookup.depth'))
          .sort((a, b) => Number(a) - Number(b))
          .reduce((depthAcc, [depth, dataAtDepth]) => {
            const bySpecies = Object.entries(
              groupBy(dataAtDepth, 'TessPlanktonLookup.species')
            ).reduce((speciesAcc, [species, speciesData]) => {
              // depthAcc[depth] = depthAcc[depth] || {};
              speciesAcc[species] = speciesAcc[depth] || {};
              speciesData.forEach((d) => {
                speciesAcc[species][d[`TessPlankton.measuredAt.${granularity}`]] = {
                  measuredAt: d[`TessPlankton.measuredAt.${granularity}`],
                  avgCellCount: d['TessPlankton.avgCellCount'],
                  maxCellCount: d['TessPlankton.maxCellCount'],
                  thresholdVal: d['thresholdVal'],
                  threshold: d['threshold'],
                  globalMaxCellCount: rawGlobalMaxCellCount
                };
              }, {});
              return speciesAcc;
            }, {});
            depthAcc[depth] = bySpecies;
            return depthAcc;
          }, {});
        sublocAcc[sublocation] = byDepth;
        return sublocAcc;
      }, {});
    return depthData;
  };
  const graph = (data: PlanktonStructure): PlotlyDataLayoutConfig => {
    const startTime = new Date();
    startTime.setDate(startTime.getDate() - 8);
    startTime.setMinutes(0);
    startTime.setSeconds(0);
    let allSpecies = [selectedSpecies];
    if (selectedSpecies.length == 0 || selectedSpecies.includes('All')) {
      allSpecies = species;
    }
    const pallet = createLocationPallet({
      palletType: 'plankton',
      locations: uniq([
        ...settings.project.planktonPolicy.map((p) => p?.generic?.key ?? p?.species.key),
        ...allSpecies
      ]).sort()
    });
    // get lists of dates and depths so we can use them for plot layout attributes
    let uniqueSublocations = [];
    let uniqueDepths = [];
    let yAxisMax = undefined;
    Object.entries(data).flatMap(([sublocation, sublocationData]) => {
      uniqueSublocations.push(sublocation);
      Object.entries(sublocationData).flatMap(([depth, depthData]) => {
        uniqueDepths.push(depth);
        if (!yAxisMax) {
          yAxisMax = Object.values(Object.values(depthData)[0])[0].globalMaxCellCount;
        }
      }, {});
    }, {});
    uniqueSublocations = uniq(uniqueSublocations);
    uniqueDepths = uniq(uniqueDepths);

    const title = settings?.site
      ? `Plankton Trends - ${settings.site.name}`
      : `Plankton Trends - All Sites`;

    const layout = {
      title: settings?.showTitle && {
        text: title,
        y: 1
      },
      autosize: true,
      // height: depthSumAggregate ? 500 : 700,
      height: !depthSumAggregate ? 500 + 50 * uniqueDepths.length : 500,
      barmode: 'group',
      showlegend: true,
      margin: { t: 50 },
      grid: {
        rows: uniqueDepths.length,
        columns: uniqueSublocations.length,
        pattern: 'independent',
        // @ts-ignore
        subplots: [],
        roworder: 'top to bottom'
      },
      hovermode: 'x unified',
      yaxis: {
        // type: 'log',
        showticklabels: true,
        automargin: true,
        rangemode: 'tozero',
        range: selectedSpecies != 'All' ? [0, Number(yAxisMax) * 1.25] : undefined,
        showgrid: true,
        showline: true,
        title: {
          standoff: 0
        },
        tickangle: 0
      },
      xaxis: {
        automargin: true,
        showticklabels: true,
        showgrid: true,
        ticklen: 5,
        type: 'date',
        range: chartRange,
        title: 'Time'
      },
      legend: {
        orientation: 'h',
        x: 0,
        y: ~~(allSpecies.length / 7) * 0.06 + (depthSumAggregate ? 1.15 : 1.1)
      },
      annotations: [
        {
          xref: 'paper',
          yref: 'paper',
          x: 0,
          borderpad: 45,
          xanchor: 'right',
          y: 0.5,
          yanchor: 'center',
          textangle: -90,
          text: 'Plankton concentration (cells / mL)',
          showarrow: false
        }
      ]
      //@ts-ignore
      // annotations
    };
    if (!depthSumAggregate) {
      layout.annotations.push({
        xref: 'paper',
        yref: 'paper',
        x: 0.5,
        borderpad: 0.4,
        xanchor: 'right',
        y: 1.09,
        yanchor: 'top',
        textangle: 0,
        text: `<b>Depth ${uniqueDepths[0]}m`,
        showarrow: false
      });
    }
    // counters for the grid layout reference for axes, which can be shared across grid rows or columns
    let xAxisCounter = 1;
    let yAxisCounter = 1;
    // counters for the grid layout itself, which is specified like [[plot, objects, for, row1], [plot, objects, for, row2]]
    let sublocationCounter = 0;
    let depthCounter = 0;
    const legendFlags = {};
    allSpecies.forEach((spp) => {
      legendFlags[spp] = true;
    });

    //@ts-ignore
    const plotData: Data[] = Object.entries(data).flatMap(([sublocation, sublocationData]) => {
      uniqueDepths.forEach((depth) => {
        if (!Object.keys(sublocationData).includes(depth)) {
          sublocationData[depth] = {};
        }
      });
      const depthPlots = Object.entries(sublocationData).flatMap(([depth, depthData]) => {
        const sppPlots = Object.entries(depthData).flatMap(([species, dataAtSpecies]) => {
          const name = settings.project.findPlanktonPolicy(species).name;

          layout.grid.subplots[depthCounter] = layout.grid.subplots[depthCounter] ?? [];
          const sppPlot = {
            mode: 'lines+markers',
            type: 'scatter',
            x: Object.keys(dataAtSpecies).flatMap((d) => new Date(d)),
            y: Object.values(dataAtSpecies).flatMap((d) => {
              return d.avgCellCount;
            }),
            offsetgroup: species,
            // width: 2e6,
            // offset: [0, allSpecies.indexOf(species) * 9e6],
            xaxis: 'x' + xAxisCounter,
            yaxis: 'y' + yAxisCounter,
            cliponaxis: false,
            hovertemplate: `<b>${name}</b><br>sublocation: %{text.sublocation}<br>depth: %{text.depth} <br> cell/ml: %{y}<extra></extra>`,
            hoverlabel: {
              align: 'left'
            },
            text: Array(Object.keys(dataAtSpecies).length).fill({
              depth: depth,
              sublocation: sublocation
            }),
            name: name,
            legendgroup: name,
            showlegend: false,
            line: { width: 3, color: hexToRgba(pallet[species], 0.7) },
            marker: {
              color: Object.values(dataAtSpecies).flatMap((d) => {
                return planktonThresholdToColor(d.threshold, { useHex: true });
              }),
              size: 13,
              line: {
                width: 2.5,
                color: pallet[species]
              }
            }
          };
          const sppHiddenPlot = {
            mode: 'lines',
            type: 'scatter',
            x: [undefined],
            y: [undefined],
            line: { width: 3, color: hexToRgba(pallet[species], 0.9) },
            name: name,
            legendgroup: name,
            offsetgroup: species,
            showlegend: legendFlags[species]
          };
          if (legendFlags[species]) {
            legendFlags[species] = false;
          }
          return [sppPlot, sppHiddenPlot];
        }, {});
        layout['yaxis' + yAxisCounter] = {
          rangemode: 'tozero',
          range: [0, Number(yAxisMax) * 1.25],
          showticklabels: true,
          showgrid: true,
          showline: true
        };
        layout['xaxis' + xAxisCounter] = {
          showticklabels: true,
          margin: { pad: 50 },
          ticklen: 5,
          showgrid: true
        };
        let xAxisMarker = 'x' + xAxisCounter;
        if (xAxisCounter == 1) {
          xAxisMarker = 'x';
        }
        let yAxisMarker = 'y' + yAxisCounter;
        if (yAxisCounter == 1) {
          yAxisMarker = 'y';
        }
        layout.grid.subplots[depthCounter].push(xAxisMarker + yAxisMarker);
        if (yAxisCounter > 1 && sublocationCounter == 0 && !depthSumAggregate) {
          layout.annotations.push({
            xref: 'paper',
            // yref: 'y' + yAxisCounter,
            yref: 'paper',
            x: 0.5,
            borderpad: 0.4,
            xanchor: 'right',
            y: ((uniqueDepths.length - yAxisCounter + 1) * 1.05) / uniqueDepths.length,
            yanchor: 'top',
            textangle: 0,
            text: `<b>Depth ${uniqueDepths[yAxisCounter - 1]}m`,
            showarrow: false
          });
        }
        if (
          depthCounter == 0 &&
          // (selectedSublocations.length >= 0 || selectedSublocations.includes('All')) &&
          uniqueSublocations.length >= 0
        ) {
          const sublocAnnotationAnchor = new Date(startTime.getTime());
          sublocAnnotationAnchor.setDate(sublocAnnotationAnchor.getDate() + 1);
          layout.annotations.push({
            xref: 'x' + xAxisCounter,
            // yref: 'y' + yAxisCounter,
            yref: 'paper',
            x: sublocAnnotationAnchor.getTime(),
            borderpad: 0,
            xanchor: 'left',
            y: 1.03,
            yanchor: 'top',
            textangle: 0,
            text: `<b>${settings.project.siteNameMappings[sublocation] ?? sublocation}`,
            showarrow: false
          });
        }
        yAxisCounter++;
        depthCounter++;
        return sppPlots;
      });
      sublocationCounter++;
      xAxisCounter++;
      depthCounter = 0;
      return depthPlots;
    });

    const [minDate, maxDate] = plotDates(plotData);
    layout.xaxis.title = `${minDate} - ${maxDate} by ${granularity}`;

    return {
      data: plotData,
      //@ts-ignore
      layout: layout
    };
  };
  const queryDimensions = [locationDimension, 'TessPlanktonLookup.species'];
  if (!depthSumAggregate) {
    queryDimensions.push('TessPlanktonLookup.depth');
  }
  const { isLoading, error, plot, resultSet } = useCubeLTG({
    cubeQuery: {
      timeDimensions: [
        {
          dimension: 'TessPlankton.measuredAt',
          granularity,
          dateRange
        }
      ],
      dimensions: queryDimensions,
      measures: ['TessPlankton.avgCellCount', 'TessPlankton.maxCellCount'],
      filters: settings.site?.smbId
        ? [
            {
              member: 'Site.id',
              operator: 'equals',
              values: [settings.site?.smbId.toString()]
            },
            { member: 'TessPlanktonLookup.method', operator: 'equals', values: ['discrete'] },
            { member: locationDimension, operator: 'set' },
            { member: 'TessPlanktonLookup.depth', operator: 'set' },
            { member: 'TessPlankton.avgCellCount', operator: 'gt', values: ['0'] }
          ]
        : [
            { member: 'TessPlanktonLookup.method', operator: 'equals', values: ['discrete'] },
            { member: locationDimension, operator: 'set' },
            { member: 'TessPlanktonLookup.depth', operator: 'set' },
            { member: 'TessPlankton.avgCellCount', operator: 'gt', values: ['0'] }
          ],
      timezone: settings.project.timezone
    },
    transform,
    graph,
    options: {
      refreshInterval: 900000,
      skip,
      dependencies: {
        depthSumAggregate,
        selectedSublocations,
        comparingSublocations,
        selectedSpecies,
        groupSubplots,
        chartRange
      }
    }
  });

  const species = useMemo(() => {
    return uniq(resultSet?.rawData().map((d) => d['TessPlanktonLookup.species'])) ?? [];
  }, [resultSet]);

  const sublocations = useMemo(() => {
    return (
      uniq(
        resultSet
          ?.rawData()
          .map((d) =>
            settings.site?.smbId
              ? d[locationDimension]
              : settings.project.siteNameMappings[d[locationDimension]]
          )
      ) ?? []
    );
  }, [resultSet]);

  return (
    <>
      {control ? (
        control({ ...settings, availableSpecies: species, availableSublocations: sublocations })
      ) : (
        <></>
      )}
      <Spacer mt="20px" />
      {isLoading ? (
        <Skeleton minH="500px" height="100%" width="100%" />
      ) : error ? (
        <GraphError minH="500px" />
      ) : plot?.data?.length > 0 ? (
        <Plot className="w-100 plankton-discrete-detail" {...plot} useResizeHandler={true} />
      ) : (
        <NoData minH="500px" />
      )}
    </>
  );
};

export default Chart;
