import { ProjectContext, ProjectContextType } from 'contexts/ProjectContext';
import { max, min } from 'date-fns';
import { uniq } from 'lodash';
import { Data, PlotlyDataLayoutConfig } from 'plotly.js';
import { useContext, useEffect, useState } from 'react';
import { paramOptions } from 'shared/Config';
import { createLocationPallet } from 'shared/functions/colorPallets';
import { locationToIndex } from 'shared/functions/location';
import { BaseChartProps } from '../types';
import { ChartSettings } from './Chart';
import useConditionData, {
  HiResHydrographyStructure,
  plotNameToConfigParam
} from './useConditionData';

type InPenPlots = {
  oxygenSat: PlotlyDataLayoutConfig;
  oxygenConcentration: PlotlyDataLayoutConfig;
  waterTemp: PlotlyDataLayoutConfig;
  depth: PlotlyDataLayoutConfig;
  salinity: PlotlyDataLayoutConfig;
};

const useConditionPlots = ({
  granularity = 'hour',
  dateRange = 'from 192 hours ago from now to 6 hours ago',
  chartRange,
  skip,
  refreshInterval,
  onDataLoaded,
  settings
}: BaseChartProps<ChartSettings>) => {
  const projectContext = useContext(ProjectContext);

  const { isLoading, error, data } = useConditionData({
    settings,
    granularity,
    dateRange,
    skip,
    refreshInterval,
    groupByMinute: 5,
    onDataLoaded
  });

  const graphAllSensors = (
    data: HiResHydrographyStructure,
    projectContext: ProjectContextType
  ): { inPenPlots: InPenPlots; minDate: Date; maxDate: Date } => {
    const locations = uniq(
      Object.values(data).flatMap((d) =>
        settings.site?.smbId ? Object.keys(d) : Object.keys(d).map((loc) => loc.split('-')[0])
      )
    );

    const dates = Object.values(data)
      .flatMap((d) => Object.values(d))
      .flatMap((o) => o.map((t) => t))
      .map((ds) => new Date(ds.time));

    const minDate = min(dates);
    const maxDate = max(dates);

    const pallet = createLocationPallet({ locations });
    const inPenPlots = Object.entries(data).reduce((acc, [sensor, sensorData]) => {
      // Filter out empty sensor charts
      if (Object.values(sensorData).every((sd) => Object.values(sd).every((d) => d === null)))
        return acc;

      const sensorParam = plotNameToConfigParam[sensor];

      //@ts-ignore
      const plotData: Data[] = Object.entries(sensorData)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .sort((a, b) => locationToIndex(a[0]) - locationToIndex(b[0]))
        .map(([sublocation, sublocationData]) => {
          const sublocationBase = sublocation.split('-');
          const tessSiteId = sublocationBase[0].replace('*', '');

          const siteName = settings.project.siteNameMappings?.[tessSiteId];

          const name = settings?.site
            ? sublocation
            : sublocationBase.length > 1
              ? `${siteName}-${sublocationBase[1]}`
              : siteName;

          const sublocationPlot = {
            mode: 'lines',
            type: 'scatter',
            x: sublocationData.map((sd) => sd.time),
            y: sublocationData.map((sd) => sd.value),
            connectgaps: true,
            visible: settings.site?.smbId
              ? true
              : sublocationBase.slice(1).includes('5m') || sublocationBase.slice(1).length == 0
                ? true
                : 'legendonly',
            name,
            hovertemplate:
              `<b>${paramOptions[sensorParam].name}: %{y:.2f} ${paramOptions[sensorParam].units} </b></br>` +
              `<b>Time: %{x} ${projectContext.timezoneLabel} </b><br>` +
              `<b>${name}</b>` +
              '<extra></extra>',
            legendgroup: sublocation,
            marker: {
              color: settings.site ? pallet[sublocation] : pallet[sublocation.split('-')[0]]
            }
          };

          return sublocationPlot;
        });

      const rangePadding = 0.1;

      // This is dumb
      // https://github.com/plotly/plotly.js/issues/400
      //@ts-ignore
      const ys = plotData.flatMap((d) => d.y).filter((y) => y);
      let min = Math.min(...ys);
      min = min - min * rangePadding;
      min = min > 0 ? min : 0;

      let max = Math.max(...ys);
      max = max + max * rangePadding;
      max = max < paramOptions[sensorParam].range[1] ? max : paramOptions[sensorParam].range[1];
      const plot: PlotlyDataLayoutConfig = {
        data: plotData,
        layout: {
          title: { text: paramOptions[sensorParam].label },
          //@ts-ignore
          range: [min, max],
          xaxis: {
            range: chartRange ?? undefined
          },
          autorange: sensorParam === 'depth' ? 'reversed' : false
        }
      };

      acc[sensor] = plot;
      return acc;
    }, {} as InPenPlots);
    return { inPenPlots, minDate, maxDate };
  };

  const [combinedPlots, setCombinedPlots] = useState<{
    inPenPlots: InPenPlots;
    minDate: Date;
    maxDate: Date;
  } | null>(null);

  useEffect(() => {
    if (!data) return;

    setCombinedPlots(graphAllSensors(data, projectContext));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading]);

  return {
    isLoading,
    error,
    plots: combinedPlots
  };
};

export default useConditionPlots;
