import { Skeleton, Spacer } from '@chakra-ui/react';
import { useCubeQuery } from '@cubejs-client/react';
import useCubeLTG from 'hooks/useCubeLTG';
import { groupBy, uniq } from 'lodash';
import { Data, PlotlyDataLayoutConfig } from 'plotly.js';
import { useMemo } from 'react';
import { createLocationPallet } from 'shared/functions/colorPallets';
import { locationToIndex } from 'shared/functions/location';
import GraphError from '../GraphError';
import NoData from '../NoData';
import NotIncluded from '../NotIncluded';
import Plot, { plotDates } from '../Plot';
import { BaseChartProps, BaseChartSettings } from '../types';

type MortalityDatum = {
  'TessMortalityLookup.sublocation'?: string;
  'Site.id'?: string;
  'TessMortality.measuredAt': string;
  'TessMortalityLookup.mortalityCauseGroupName': string;
  'TessMortalityLookup.mortalityCauseName': string;
  'TessMortality.mortalityCountSum': number;
  'TessMortality.mortalityBiomassKgSum': number;
};

type MortalityStructure = {
  [mortalityCause: string]: {
    // [sublocation: string]: {
    [measuredAt: string]: {
      count: number;
      biomass: number;
      cause_group_name: string;
      cause_name: string;
      sublocations: { name: string; value: string };
    };
    // };
  };
};

export type ChartSettings = BaseChartSettings & {
  showInCauseBiomass?: boolean;
  showByCauseName?: boolean;
  selectedSublocation?: { value: string; label: string };
};

export type ChartInputs = {
  sublocations?: { value: string; label: string }[];
};

const Chart = ({
  dateRange = 'Last 30 days',
  chartRange,
  granularity = 'day',
  skip,
  settings,
  control,
  onDataLoaded
}: BaseChartProps<ChartSettings, ChartInputs>) => {
  const locationDimension = settings.site?.smbId ? 'TessMortalityLookup.sublocation' : 'Site.id';

  const transform = (data: MortalityDatum[]): MortalityStructure => {
    let filteredDataSublocation = data;
    if (settings?.selectedSublocation?.value && settings?.selectedSublocation?.value != 'All') {
      // filter to sublocation
      filteredDataSublocation = filteredDataSublocation.filter(
        (d) => d[locationDimension] == settings.selectedSublocation.value
      );
    }
    const causes = filteredDataSublocation.reduce((causeAcc, d) => {
      const causeName = settings.showByCauseName
        ? d['TessMortalityLookup.mortalityCauseName']
        : d['TessMortalityLookup.mortalityCauseGroupName'];
      const causeValue = settings.showInCauseBiomass
        ? d['TessMortality.mortalityBiomassKgSum']
        : d['TessMortality.mortalityCountSum'];
      causeAcc[causeName] = causeAcc[causeName] ? causeAcc[causeName] + causeValue : causeValue;
      return causeAcc;
    }, {});
    // Need the traces in order by value so the stacked bars have big bars on bottom.
    const orderedCauses = Object.entries(causes)
      .sort((a, b) => {
        return Number(a[1]) - Number(b[1]);
      })
      .reverse()
      .map((d) => {
        return d[0];
      }, {});
    // .reduce((acc, d) => {
    //   acc[d[0]] = Number(d[1]);
    //   return acc;
    // }, {});

    // Group by Mortality Cause, but mind the Switch when choosing whether to use Cause Name or Cause Group Name
    const byCause = groupBy(filteredDataSublocation, (d) => {
      return settings.showByCauseName
        ? d['TessMortalityLookup.mortalityCauseName']
        : d['TessMortalityLookup.mortalityCauseGroupName'];
    });
    const causeData = Object.keys(byCause).reduce((causeAcc, cause: string) => {
      // const bySublocation = groupBy(byCause[cause], (d) => d['TessMortalityLookup.sublocation']);
      const byMeasuredAt = groupBy(byCause[cause], (d) => d['TessMortality.measuredAt']);
      causeAcc[cause] = Object.keys(byMeasuredAt).reduce((acc, timestamp: string) => {
        acc[timestamp] = byMeasuredAt[timestamp].reduce((sublocAcc, measurements) => {
          // Get the total count
          sublocAcc['count'] = sublocAcc['count']
            ? Number(sublocAcc['count']) + Number(measurements['TessMortality.mortalityCountSum'])
            : Number(measurements['TessMortality.mortalityCountSum']);
          // Get the total biomass
          sublocAcc['biomass'] = sublocAcc['biomass']
            ? Number(sublocAcc['biomass']) +
              Number(measurements['TessMortality.mortalityBiomassKgSum'])
            : Number(measurements['TessMortality.mortalityBiomassKgSum']);
          // Concat a list of objects with cage-by-cage data
          //// Mind the settings.showInCauseBiomass toggle when choosing which data to display for cages.
          //// This will display in hovertext
          sublocAcc['sublocations'] = sublocAcc['sublocations']
            ? sublocAcc['sublocations'].concat({
                [measurements[locationDimension]]: settings.showInCauseBiomass
                  ? measurements['TessMortality.mortalityBiomassKgSum']
                  : measurements['TessMortality.mortalityCountSum']
              })
            : [
                {
                  [measurements[locationDimension]]: settings.showInCauseBiomass
                    ? measurements['TessMortality.mortalityBiomassKgSum']
                    : measurements['TessMortality.mortalityCountSum']
                }
              ];
          // if (sublocAcc['sublocations'].length > 1) {
          //   sublocAcc['sublocations'] = sublocAcc['sublocations'].sort(
          //     (a, b) => locationToIndex(a) - locationToIndex(b)
          //   );
          // }
          // Get cause name
          sublocAcc['cause_name'] = sublocAcc['cause_name'] ?? cause;
          return sublocAcc;
        }, {});
        return acc;
      }, {});
      return causeAcc;
    }, {});
    const orderedCauseData = orderedCauses.reduce((acc, cause) => {
      acc[cause] = causeData[cause];
      return acc;
    }, {});
    return orderedCauseData;
  };

  const graph = (data: MortalityStructure, dependencies?: any): PlotlyDataLayoutConfig => {
    const causes = uniq(Object.keys(data)).sort((a, b) => locationToIndex(a) - locationToIndex(b));

    const causePallet = settings?.showByCauseName ? allMortalityCauses : allMortalityCauseGroups;
    const pallet = createLocationPallet({ locations: causePallet, palletType: 'mortalityCauses' });
    const causeLegend = {};
    //@ts-ignore
    const plotData: Data[] = causes.flatMap((cause) => {
      // Cause data
      //// Sublocation data
      ////// Time series data
      // Order such that largest values are at the bottom
      const causeData = data[cause];
      const showLegend = causeLegend[cause] ? false : true;
      causeLegend[cause] = true;
      // Sublocations for cause
      const sublocs = uniq(
        Object.values(causeData).flatMap((d) => {
          const sublocationString = Object.values(d.sublocations).flatMap((sublocations) => {
            return Object.keys(sublocations).flatMap((d) => {
              return d;
            });
          });
          return sublocationString;
        })
      ).sort((a, b) => locationToIndex(a) - locationToIndex(b));
      return {
        type: 'bar',
        name: cause,
        x: Object.keys(causeData),
        y: Object.values(causeData).flatMap<number>((d) =>
          dependencies?.biomassToggle ? d.biomass : d.count
        ),
        marker: {
          color: pallet[cause]
        },
        legendgroup: cause,
        showlegend: showLegend,
        hovertemplate: `<b>%{x}</b><br>Cause: <b>%{text.cause}</b><br><br>%{text.sublocations}<br><br>${
          dependencies?.biomassToggle ? 'Total: <b>%{y:.1f}</b>' : 'Total: <b>%{y:0.f}</b>'
        }<extra></extra>`,
        hoverlabel: {
          align: 'left'
        },
        text: Object.values(causeData).flatMap((d) => {
          const sublocString = sublocs
            .flatMap((subloc) => {
              return Object.values(d.sublocations).flatMap((sublocObj) => {
                if (sublocObj[subloc]) {
                  return settings.site?.smbId
                    ? `${subloc}: <b>${
                        dependencies?.biomassToggle
                          ? sublocObj[subloc].toFixed(1)
                          : sublocObj[subloc]
                      }</b>`
                    : `${settings.project.siteNameMappings[subloc]}: <b>${sublocObj[subloc].toFixed(
                        1
                      )}</b>`;
                }
              });
            })
            .filter((d) => {
              return d !== undefined;
            })
            .join('<br>');
          return {
            value: dependencies?.biomassToggle ? d.biomass : d.count,
            sublocations: sublocString,
            cause: d.cause_name
          };
        })
      };
    });

    const title = settings?.site
      ? `Mortality By Cause - ${settings.site.name}`
      : `Mortality By Cause - All Sites`;

    const measurementLabel = settings.showInCauseBiomass ? 'Weight (kg)' : 'Count';
    const causeLabel = settings.showByCauseName ? 'Cause' : 'Cause Group';

    const yLabel = `${measurementLabel} By ${causeLabel}`;

    const [minDate, maxDate] = plotDates(plotData);

    const layout = {
      title: settings?.showTitle && {
        text: title,
        y: 1
      },
      barmode: 'stack',
      yaxis: {
        title: {
          text: yLabel
        }
      },
      xaxis: {
        title: `${minDate} - ${maxDate} by ${granularity}`,
        range: chartRange
      },
      autosize: true,
      showlegend: true,
      legend: {
        orientation: 'h',
        x: 0,
        y: 1.05 + Math.floor(causes.length / 5) * 0.04,
        // traceorder: 'normal',
        tracegroupgap: 0
      },
      margin: {
        t: 25
      },
      hovermode: 'closest'
      // hovermode: 'x unified'
    };

    return {
      //@ts-ignore
      data: plotData,
      //@ts-ignore
      layout: layout
    };
  };

  // Distinct Mortality-Causes for Project-Wide Color Mappings
  const mortalityCauseQuery = useCubeQuery(
    {
      dimensions: [
        'TessMortalityLookup.mortalityCauseGroupName',
        'TessMortalityLookup.mortalityCauseName'
      ]
    },
    {
      skip: false,
      subscribe: false
    }
  );
  const allMortalityCauseGroups: string[] = uniq(
    mortalityCauseQuery.resultSet
      ?.rawData()
      .map((d) => String(d['TessMortalityLookup.mortalityCauseGroupName']))
  ).sort((a, b) => locationToIndex(a) - locationToIndex(b));
  const allMortalityCauses: string[] = uniq(
    mortalityCauseQuery.resultSet
      ?.rawData()
      .map((d) => String(d['TessMortalityLookup.mortalityCauseName']))
  ).sort((a, b) => locationToIndex(a) - locationToIndex(b));

  const { isLoading, error, plot, resultSet } = useCubeLTG({
    cubeQuery: {
      measures: [
        'TessMortality.mortalityCountSum',
        'TessMortality.mortalityCountSum',
        'TessMortality.mortalityBiomassKgSum',
        'TessMortality.mortalityBiomassKgSum'
      ],
      timeDimensions: [
        {
          dimension: 'TessMortality.measuredAt',
          granularity,
          dateRange
        }
      ],
      dimensions: [
        locationDimension,
        'TessMortalityLookup.mortalityCauseGroupName',
        'TessMortalityLookup.mortalityCauseName'
      ],
      filters: settings.site?.smbId
        ? [
            {
              member: 'Site.id',
              operator: 'equals',
              values: [settings.site?.smbId.toString()]
            },
            { member: 'TessMortality.mortalityCountSum', operator: 'gt', values: ['0'] }
          ]
        : [{ member: 'TessMortality.mortalityCountSum', operator: 'gt', values: ['0'] }],
      timezone: settings.project.timezone
    },
    transform,
    graph,
    options: {
      skip,
      dependencies: {
        biomassToggle: settings.showInCauseBiomass,
        causeGroupToggle: settings.showByCauseName,
        selectedSublocation: settings.selectedSublocation,
        chartRange
      },
      onDataLoaded
    }
  });

  const sublocations = useMemo(() => {
    return (
      uniq(resultSet?.rawData().map((d) => d[locationDimension]))
        .sort((a, b) => locationToIndex(a) - locationToIndex(b))
        .map((d) => {
          return settings.site?.smbId
            ? { value: d, label: d }
            : {
                value: d,
                label: settings.project.siteNameMappings[d]
              };
        }) ?? []
    );
  }, [resultSet]);

  if (isLoading) {
    return <Skeleton minH="450px" height="100%" width="100%" />;
  }

  if (error) {
    return <GraphError minH="450px" />;
  }

  return plot?.data?.length === 0 && settings.project.freeTrial ? (
    <NotIncluded minH="450px" />
  ) : plot?.data?.length === 0 ? (
    <NoData minH="450px" />
  ) : (
    <>
      {control ? control({ sublocations: sublocations }) : <></>}
      <Spacer mt="20px" />
      <Plot className="w-100" useResizeHandler={true} {...plot} />
    </>
  );
};

export default Chart;
