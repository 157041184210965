import { ApolloError } from '@apollo/client';
import { CheckCircleIcon, SmallCloseIcon, StarIcon } from '@chakra-ui/icons';
import {
  Avatar,
  Box,
  Button,
  Flex,
  HStack,
  Input,
  Skeleton,
  TableContainer,
  Text
} from '@chakra-ui/react';
import { User, permissions } from '@scoot/permissions';
import UsershipModal from 'components/Modals/UsershipModal';
import { DataTable } from 'components/Tables/DataTable';
import { ProjectContext } from 'contexts/ProjectContext';
import { UserContext } from 'contexts/UserContext';
import { format as formatDate } from 'date-fns';
import { useContext, useEffect, useState } from 'react';
import { Column } from 'react-table';

type UserTableProps = {
  users?: User[];
  error?: ApolloError;
  loading: boolean;
  onUserClicked: (user: User) => void;
  onNewUserClicked: () => void;
};

const UserTable = ({ users, error, loading, onUserClicked, onNewUserClicked }: UserTableProps) => {
  const userContext = useContext(UserContext);
  const project = useContext(ProjectContext);
  const [userFilter, setUserFilter] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [viewingUsership, setViewingUsership] = useState<User | null>(null);

  useEffect(() => {
    if (!users) return;

    if (userFilter) {
      setFilteredUsers(
        users.filter((u) => u.email.toLowerCase().includes(userFilter.toLowerCase()))
      );
    } else {
      setFilteredUsers(users);
    }
  }, [users, userFilter]);

  //@ts-ignore
  const columns: Column<User> = [
    {
      id: 'email',
      Header: 'Email',
      accessor: (user) => user.email,
      Cell: (state) => {
        if (state.row.original.avatarUrl) {
          return (
            <HStack>
              <Avatar size="xs" src={state.row.original.avatarUrl} />
              <Text>{state.row.original.email}</Text>
            </HStack>
          );
        } else {
          return <Text>{state.row.original.email}</Text>;
        }
      }
    },
    {
      id: 'admin',
      Header: 'Admin',
      accessor: (user) =>
        permissions.canAdminProject(user, project.id) ? (user.superuser ? 'a' : 'b') : 'c',
      Cell: (rowState) => {
        return (
          <Box ml="12px">
            {rowState.row.original.superuser ? (
              <StarIcon color="blue.500" />
            ) : permissions.canAdminProject(rowState.row.original, project.id) ? (
              <CheckCircleIcon color="green.500" />
            ) : (
              <SmallCloseIcon color="red.500" />
            )}
          </Box>
        );
      }
    },
    {
      id: 'lastActivity',
      Header: 'Last Activity',
      accessor: (user) => user,
      Cell: ({ value }: { value: User }) => {
        const lastActive = value?.lastActivity
          ? formatDate(new Date(value.lastActivity), 'PP')
          : '';
        return userContext.superuser ? (
          <HStack>
            <Text>{lastActive}</Text>
            <Button
              variant="link"
              py="0"
              my="0"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setViewingUsership(value);
              }}>
              Details...
            </Button>
          </HStack>
        ) : (
          <Text>{lastActive}</Text>
        );
      }
    },
    {
      id: 'createdAt',
      Header: 'Created At',
      accessor: (user) => user.creationTime,
      Cell: ({ value }: { value?: string }) => (value ? formatDate(new Date(value), 'PP') : '')
    }
  ];

  if (error) {
    return (
      <Text>An error has occurred loading your user table. Please try refreshing the page.</Text>
    );
  }

  if (loading) {
    return <Skeleton w="100%" h="400px" />;
  }

  return (
    <>
      <Input
        placeholder="Filter by email..."
        onChange={(e) => setUserFilter(e.currentTarget.value)}
      />
      <Flex w="100%" mt="10px">
        <Box mr="10px">
          {permissions.canAdminProject(userContext, project.id) && (
            <Button
              data-cypress="create-user"
              w="100%"
              mb="10px"
              colorScheme="blue"
              variant="solid"
              onClick={() => {
                onNewUserClicked();
              }}>
              New User
            </Button>
          )}
        </Box>
        <TableContainer w="100%">
          <DataTable
            //@ts-ignore
            columns={columns}
            data={filteredUsers}
            initSortBy={{ id: 'email', desc: false }}
            onRowClick={(row) => {
              onUserClicked(row.original);
            }}
          />
        </TableContainer>
      </Flex>
      <UsershipModal
        user={viewingUsership}
        isOpen={!!viewingUsership}
        onClose={() => setViewingUsership(null)}
      />
    </>
  );
};

export default UserTable;
